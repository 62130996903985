import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact-container">
        <h1>ទំនាក់ទំនងមកកាន់យេីងខ្ញុំ!</h1>
        <p>
          បំពេញទម្រង់ខាងក្រោម ដើម្បីទាក់ទងជាមួយសមាជិកក្រុមរបស់យើង។
          យើង​នឹង​ព្យាយាម​ទាក់ទង​ទៅ​អ្នក​វិញ​ក្នុង​ពេល​ឆាប់​តាម​ដែល​អាច​ធ្វើ​ទៅ​បាន។
          ប្រសិនបើយើងមិនឆ្លើយតបទាន់ពេលវេលាសូមទូរស័ព្ទមកយើងខ្ញុំ
          ឬក៏លោកអ្នកទាក់ទងមកកាន់ផេកហ្វេសប៊ុករបស់យើងផ្ទាល់ ។
        </p>

        <form>
          <div className="form-group">
            <label>សូមប្រាប់ឈ្មោះរបស់អ្នក</label>
            <input
              type="text"
              name="firstname"
              id="firstname"
              required
              className="form-element"
              //   placeholder="ឈ្មោះរបស់អ្នក"
            />
          </div>

          <div className="form-group">
            <label>សូមវាយលេខទូរស័ព្ទរបស់អ្នក</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              required
              className="form-element"
              placeholder=""
            />
          </div>

          <div className="form-group full">
            <label>អ្នកអាចសរសេរសារមកាន់យើងខ្ញុំបាន</label>
            <textarea
              name="message"
              id="message"
              className="form-element"
              placeholder="សូមមេត្តាប្រាប់ពីគោលបំណងរបស់លោកអ្នកហើយជាមួយនឺងលេខទូរស័ព្ទរបស់អ្នក ។"
            ></textarea>
          </div>
          <div className="submit-group">
            <input type="submit" value="ចុចផ្ញើសារ" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
