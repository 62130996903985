import product1 from "../assets/product1.png";
import product2 from "../assets/product2.png";
import product3 from "../assets/product3.png";
import product4 from "../assets/product4.png";

export const productData = [
  {
    id: 1,
    title: "ជីគីមី ម៉ាឌ្រីក 1",
    desc: "desc",
    image: product1,
  },
  {
    id: 2,
    title: "ជីគីមី MATRIX 2",
    desc: "desc",
    image: product2,
  },
  {
    id: 3,
    title: "ជីគីមី ២១-០-០",
    desc: "desc",
    image: product3,
  },
  {
    id: 4,
    title: "ជីធម្មជាតិ ១០០%",
    desc: "desc",
    image: product4,
  },
];
