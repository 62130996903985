import "./Promotion.css";
import promotionImg1 from "../../assets/promotion.jpg";
import promotionImg2 from "../../assets/promotion2.jpg";

const Promotion = () => {
  return (
    <div className="promotion">
      <p className="title">ប្រម៉ូសិនពិសេសរបស់ស្រែលាភ</p>
      <div className="promotion-container">
        <div className="image-wrapper">
          <img src={promotionImg1} alt="promotion" />
        </div>
        <div className="image-wrapper">
          <img src={promotionImg2} alt="promotion" />
        </div>
      </div>
    </div>
  );
};

export default Promotion;
