import React from "react";
import product from "../../assets/product.jpeg";
import "./About.css";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="container">
        <img src={product} alt="product" className="about-img" />
        <div className="col-2">
          <h2>អំពីយេីង</h2>
          <span className="line"></span>
          <p>
            ផលិតផលស្រែលាភយើងខ្ញុំមានគុណភាពខ្ពស់ដែលអាចទុកចិត្តបាន ១០០% ។
            លោកអ្នកអាចយកវាទៅប្រើជាមួយដំណាំគ្រប់ប្រភេទ ។
            មានអតិថិជនជាច្រើនបានប្រើប្រាស់ជីរបស់ស្រែលាភហើយទទួលបានលទ្ធិផលល្អ ។
            ម្ចាស់ចម្ការជាច្រើនអ្នកសប្បាយចិត្តជាខ្លាំងដែលដំណាំរបស់ពួកគាត់ទទួលបានផ្លែផ្កាគាប់ប្រសើរ
            ។ បើពុកម៉ែបងប្អូនមានចម្ងល់អាចទាក់ទងមកកាន់ពួកយើងបាន
            យើងខ្ញុំនឺងជួយផ្តល់យល់បល់អំពីចម្ការរបស់បងប្អូន ។
            ស្រែលាភយើងខ្ញុំមានការិយាល័យនៅក្រុងបាត់ដំបង ។
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
