import React from "react";
import { Link } from "react-scroll";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-left">
          <ul>
            <li className="nav-item">
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                ទំព័រដើម
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                អំពីយើង
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="product"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                ផលិតផល
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                ទំនាក់ទំនង
              </Link>
            </li>
          </ul>
          <span className="line"></span>
        </div>

        <div className="footer-right">
          <iframe
            className="map"
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.2221422162775!2d103.19984790000001!3d13.085103199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310549bfd2d056f9%3A0xeab5bbd9db2d68c1!2s35PX%2B3XH%20Psar%20Leu%2C%20Street%20151%2C%20Krong%20Battambang%2C%20Cambodia!5e0!3m2!1sen!2sus!4v1668738650105!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="bottom">
          {/* <span className="line"></span> */}
          <p>{currentYear} Sre Leap, Inc. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
