import "./Card.css";

const Card = ({ title, desc, image }) => {
  return (
    <div className="card">
      <div className="card-container">
        <div className="card-image-wrapper">
          <img src={image} alt="" />
        </div>
        <div className="card-desc">
          <h3>{title}</h3>
          {/* <p> {desc}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Card;
