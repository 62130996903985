import "./Feature.css";
import feature2 from "../../assets/feature02.JPG";
import feature3 from "../../assets/feature03.JPG";

const Feature = () => {
  return (
    <div className="feature-container">
      <h3>អត្ថប្រយោជន៍របស់ជីម៉ាឌ្រីត</h3>
      <div className="feature-img-wrapper">
        <div className="feature-img">
          <img src={feature3} alt="រូបភាព" />
        </div>
        <div className="feature-img">
          <img src={feature2} alt="រូបភាព" />
        </div>
      </div>
    </div>
  );
};

export default Feature;
