import { productData } from "../../utils/productData";
import Card from "../card/Card";
import Feature from "../feature/Feature";
import "./Product.css";

const Product = () => {
  return (
    <main className="product-container" id="product">
      <h3 className="product-title">ផលិតផលរបស់ស្រែលាភ</h3>
      <div className="product-wrapper">
        {productData.map((item) => (
          <Card
            key={item.id}
            title={item.title}
            desc={item.desc}
            image={item.image}
          />
        ))}
      </div>

      <Feature />
    </main>
  );
};

export default Product;
