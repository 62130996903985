import React from "react";
import "./Hero.css";
import { FaFacebook } from "react-icons/fa";

const Hero = () => {
  return (
    <div className="hero" id="hero">
      <div className="content">
        <p>ទំនាក់ទំនងទូរស័ព្ទលេខ៖ </p>
        <p>+855 97 780 2802</p>
        <p>ជីដែលមានគុណភាពខ្ពស់</p>
        <p>មានទីតាំងនៅក្រុងបាត់ដំបង</p>

        <a href="tel:+855977802802">
          <button className="button">
            ការប្រឹក្សាពិគ្រោះយោបល់ដោយឥតគិតថ្លៃ
          </button>
        </a>

        <div className="facebook-icon">
          <p>ទំនាក់ទំនងតាមប្រព័ន្ធ FACEBOOK </p>
          <a
            href="https://www.facebook.com/profile.php?id=100068717177754"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook className="fb-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
