// import { useEffect, useState } from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Product from "./components/products/Product";
import Promotion from "./components/promotion/Promotion";

function App() {
  // const [showPromotion, setShowPromotion] = useState(false);

  // useEffect(() => {
  //   setShowPromotion(true);

  //   setTimeout(() => setShowPromotion(false), 2000);
  // }, []);

  return (
    <div className="App">
      <Navbar />
      <Hero />
      {/* {showPromotion && <Promotion />} */}
      <Promotion />
      <Product />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
